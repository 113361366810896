/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Button,
  Icon,
  Typography,
  IconButton,
  SvgIcon,
  ButtonGroup,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { makeStyles } from '@material-ui/styles';
import _, { filter, debounce } from 'lodash';
import moment from 'moment-timezone';

import Stepper from 'shared/components/stepper/Stepper';
import Step from 'shared/components/stepper/Step';
import RedButton from 'shared/components/Mui/button/RedButton';
import BlueGreyishButton from 'shared/components/Mui/button/BlueGreyishButton';
import {ReactComponent as CoffeeIcon} from 'assets/images/coffee1.svg';
import {ReactComponent as LunchIcon} from 'assets/images/lunch.svg';
import { getActiveSession } from 'api/Api';

const useStyles = makeStyles(theme => ({
  breakIcons: props => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    padding: 7,
    'border-radius': '5px',
    '&.active': {
      borderColor: '#D2E2EA',
    },
  }),
  highlightColor: {
    color: theme.palette.highlight.main,
  },
  highlightBackground: {
    background: theme.palette.highlight.main,
    color: theme.palette.highlight.contrastText,
  },
  blueGreyColor: {
    color: theme.palette.blueGrey.main,
  },
}));
const Break = ({
  breaksettings, webclock, onUserCommentModal, onEndBreak, user_comment, onStartBreak,
}) => {
  const classes = useStyles();
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  const [activeBreak, setActiveBreak] = useState(webclock.settings_store_id || (breaksettings &&
    breaksettings.length > 0 &&
    breaksettings[0]) ||
    []);
  const [clockinTime, setClockinTime] = useState(webclock.clockIn);
  const [webClock, setwebClock] = useState(webclock);

  const $onStartBreak = () => {
    if (permissions && permissions.write_hr_clockin && webClock.status === 2) {
      onStartBreak({
        timecard_id: webclock.timecard_id,
        settings_store_id: activeBreak.settings_store_id,
      });
    }
  };
  const $onEndBreak = () => {
    if (permissions && permissions.write_hr_clockin && webClock.status === 3 && webclock.timecard_break_id) {
      onEndBreak({
        id: webclock.timecard_break_id,
      });
    }
  };
  return (
    (permissions.write_hr_clockin ? (
      <Paper>
        <Grid
          container
          direction="column"
          style={{ width: '177px', height: '180px', padding: '8px' }}
          alignItems="center"
          justify="space-evenly"
        >
          <Grid container spacing={2} justify="center" item>
            {breaksettings &&
                                    breaksettings.length > 0 &&
                                    filter(breaksettings, { short_description: 'Lunch Break' }).map(breakSetting => (
                                      <Grid item key={breakSetting.settings_store_id}>
                                        <IconButton
                                          className={`${activeBreak.settings_store_id ===
                                                    breakSetting.settings_store_id && 'active'} ${classes.breakIcons
                                                    }`}
                                          onClick={() => webClock.status === 2 && setActiveBreak(breakSetting)}
                                          title="Lunch Break"
                                        >
                                          <SvgIcon
                                            component={LunchIcon}
                                            color={
                                                        activeBreak.settings_store_id !== breakSetting.settings_store_id
                                                            ? 'disabled'
                                                            : undefined
                                                    }
                                          />
                                        </IconButton>
                                      </Grid>
                                    ))}
            {breaksettings && breaksettings.length > 0 && _.chain(breaksettings)
                                        .filter({ short_description: 'Coffee Break' })
                                        .orderBy(['is_paid'], ['desc'])
                                        .take()
                                        .value()
                                        .map(breakSetting => (
                                          <Grid item key={breakSetting.settings_store_id}>
                                            <IconButton
                                              className={`${activeBreak.settings_store_id === breakSetting.settings_store_id &&
                                                        'active'} ${classes.breakIcons}`}
                                              onClick={() => webClock.status === 2 && setActiveBreak(breakSetting)}
                                              title="Coffee Break"
                                            >
                                              <SvgIcon
                                                component={CoffeeIcon}
                                                color={
                                                            activeBreak.settings_store_id !== breakSetting.settings_store_id
                                                                ? 'disabled'
                                                                : undefined
                                                        }
                                              />
                                            </IconButton>
                                          </Grid>
                                        ))}
          </Grid>
          <Grid item>
            <Typography className={classes.highlightColor} variant="h2" component="span">
              {(activeBreak.duration) ||
                                        0}
            </Typography>
            <Typography className={classes.highlightColor} variant="caption">
                                    min
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.blueGreyColor} variant="subtitle2" component="span">
              {activeBreak.short_description === 'Lunch Break'
                                        ? 'Lunch - '
                                        : activeBreak.short_description === 'Coffee Break' && 'Coffee - '}
              {activeBreak.is_paid
                                        ? 'Paid Break'
                                        : 'Unpaid Break'}
            </Typography>
          </Grid>
          <Grid item>
            {webClock.status === 3 ?
              <ButtonGroup variant="contained">
                <RedButton variant="contained" onClick={$onEndBreak}>
                                            End Break
                </RedButton>
                <Button onClick={() => onUserCommentModal({ type: 'end_break' })} size="small" className={classes.highlightBackground}>
                  {user_comment && user_comment.end_break
                                                ? <CommentIcon fontSize="small" />
                                                : <AddCommentIcon fontSize="small" />
                                            }
                </Button>
              </ButtonGroup>
                                    :
              <ButtonGroup variant="contained">
                <Button variant="contained" onClick={$onStartBreak} color="secondary">
                                            Start Break
                </Button>
                <Button onClick={() => onUserCommentModal({ type: 'start_break' })} size="small" className={classes.highlightBackground}>
                  {user_comment && user_comment.start_break
                                                ? <CommentIcon fontSize="small" />
                                                : <AddCommentIcon fontSize="small" />
                                            }
                </Button>
              </ButtonGroup>
                                }
          </Grid>
        </Grid>
      </Paper>
    ) : null
    )
  );
};
export default Break;

